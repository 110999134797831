<template>
  <div class="choiceData">
    <!-- <label @click="buttonProjectManagerFn" class="choiceDataBtn"> -->
    <label @click="dlgVisible = true" class="choiceDataBtn">
      <slot name="button"> </slot>
    </label>
    <el-dialog
      class="choiceDataCon"
      :title="parameter.title"
      :visible.sync="dlgVisible"
      :append-to-body="true"
      width="60%"
      @close="onClosed"
    >
      <div class="el-transfer-panel" v-if="parameter.type != 'radio'">
        <p class="el-transfer-panel__header">
          <label class="el-checkbox">
            <span class="el-checkbox__label">
              已选
              <span>{{ choiceList.length }}</span>
            </span>
          </label>
        </p>

        <div class="el-transfer-panel__body">
          <div class="el-checkbox-group el-transfer-panel__list">
            <div v-for="(n, i) in choiceList" class="el-checkbox el-transfer-panel__item" :key="i">
              <slot name="choiceItem" :data="n">{{ n.id }}</slot>

              <!-- <i class="move el-icon-close" @click="remove(i)"></i> -->
              <template v-if="parameter.type == 'checkboxMove'">
                <i v-if="i > 0" class="move el-icon-top" @click="move(i, 1)"></i>
                <i
                  v-if="i < choiceList.length - 1"
                  class="move el-icon-bottom"
                  @click="move(i, -1)"
                ></i>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="selected-list">
        <div class="mainList">
          <slot name="search" :params="parameter.params" :search="pageChangeHandler"> </slot>

          <div class="mainList_content">
            <div class="mainList_content_sub">
              <div class="mainList_operation_search" style="margin-bottom: 8px" v-if="issearch">
                <span style="margin-right: 8px"
                  >项目名称:
                  <el-select
                    style="width: 75%"
                    v-model="parameterprojectName.projectName"
                    @change="dataUpdate"
                    clearable
                    filterable
                    placeholder="请输入或选择项目名"
                    class="ipt_width"
                  >
                    <el-option
                      v-for="item in projectList"
                      :key="item.id"
                      :label="item.projectName"
                      :value="item.projectName"
                    >
                    </el-option>
                  </el-select>
                </span>

                <el-button
                  type="primary"
                  size="medium"
                  plain
                  icon="el-icon-search"
                  @click="getProjectpageChangeHandler(1)"
                  >搜索</el-button
                >
                <el-button
                  type="warning"
                  size="medium"
                  plain
                  icon="el-icon-search"
                  @click="handleReset"
                  >清空</el-button
                >
              </div>
              <el-table
                ref="multipleTable"
                :border="true"
                :data="selectedList"
                tooltip-effect="dark"
                height="string"
                @row-click="rowClick"
                v-loading="tableLoading"
              >
                <slot name="tableColumn-left"></slot>

                <el-table-column label="操作" width="100" align="center">
                  <template slot-scope="scope">
                    <el-button
                      v-if="parameter.type == 'radio'"
                      type="text"
                      size="small"
                      @click.stop="
                        $emit('choiceDataChange', scope.row)
                        dlgVisible = false
                      "
                      :disabled="scope.row.Selected == 1"
                    >
                      <span v-if="scope.row.Selected == 1">已选</span>
                      <span v-else>选择</span></el-button
                    >
                    <el-checkbox
                      :disabled="scope.row.isCheckBox"
                      v-else
                      v-model="scope.row.shuttleBoxChecked"
                      @click.stop=""
                    ></el-checkbox>
                  </template>
                </el-table-column>
                <slot name="tableColumn"></slot>
              </el-table>
              <el-pagination
                @current-change="pageChangeHandler"
                @size-change="handleSizeChange"
                :current-page="filter.pageNow"
                :page-size="filter.pageSize"
                prev-text="上一页"
                next-text="下一页"
                :total="filter.total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>

      <div v-if="parameter.type != 'radio'" slot="footer" class="dialog-footer">
        <el-button type="success" @click="save">确定</el-button>
        <el-button type="info" plain @click="dlgVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ChoiceData',
  components: {},
  props: {
    projectListResponseList: {
      type: Array,
      default: function () {
        return []
      },
    },
    deptCompetentManagerList: {
      type: Array,
      default: function () {
        return []
      },
    },
    projectManagerFnList: {
      type: Array,
      default: function () {
        return []
      },
    },
    deputyGeneralManagerList: {
      type: Array,
      default: function () {
        return []
      },
    },
    selectedData: {
      type: Array,
      default: function () {
        return []
      },
    },
    configure: {
      type: Object,
      default: function () {
        return {}
      },
    },
    issearch: {
      type: Boolean,
      default: false,
    },
    projectManagerDlgVisible: {
      type: Boolean,
      default: false,
    },
    projectManagerOptins: {
      type: [Number, String],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      parameter: {
        title: '请选择',
        request: null, //请求api例如  this.$api.department.findPage
        type: 'checkbox', // radio 单选  checkbox 多选   checkboxMove 多选可移动
        params: {},
      }.combination(this.configure),
      dlgVisible: this.projectManagerDlgVisible || false,
      selectedListLoad: false,
      selectedList: [],
      choiceList: this.selectedData.deepClone(),
      filter: {
        pageNow: 1,
        total: 1,
        pageSize: 50,
      },

      projectList: [],
      parameterprojectName: {
        pageNow: 1,
        projectName: null,
        pageSize: 10000,
      },
      tableLoading: this.loading || false,
    }
  },
  computed: {
    ...mapState({
      projectInformation: state => state.project.projectInformation,
    }),
  },
  watch: {
    projectManagerFnList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.selectedList = []
          this.selectedList = newVal
          this.filter.total = newVal.length
        }
      },
    },
    deputyGeneralManagerList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.selectedList = []
          this.selectedList = newVal
          this.filter.total = newVal.length
        } else {
          this.selectedList = []
        }
      },
    },
    deptCompetentManagerList: {
      deep: true,
      immediate: true,
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.selectedList = []
          this.selectedList = newVal
          this.filter.total = newVal.length
        } else {
          this.selectedList = []
        }
      },
    },
    configure: {
      deep: true,
      handler: function (newVal) {
        this.parameter = { ...this.parameter, ...newVal }
      },
    },
    selectedData(newValue) {
      this.choiceList = newValue.deepClone()
      for (let i = 0; i < this.selectedList.length; i++) {
        let obj = this.selectedList[i]
        if (
          this.choiceList.some(function (s) {
            return s.id == obj.id
          })
        ) {
          obj.shuttleBoxChecked = true
          obj.isCheckBox = true
        } else {
          obj.shuttleBoxChecked = false
          obj.isCheckBox = false
        }
      }
    },
    dlgVisible(val) {
      if (val) {
        this.parameterprojectName.projectName = null
        this.selectedList.forEach(v => {
          this.choiceList.forEach(k => {
            if (v.userName == k.userName && v.isCheckBox) {
              v.joinDate = k.joinDate
            }
          })
        })
        this.$emit('updateDay')
        if (this.issearch) {
          this.$api.project
            .listProject({
              pageSize: 99999,
              pageNow: 1,
            })
            .then(res => {
              this.projectList = res.data.records
            })
            .catch(err => {
              console.log(err)
            })
        }
      } else {
        this.selectedList.forEach(v => {
          v.joinDate = ''
          if (v.shuttleBoxChecked && !v.isCheckBox) {
            v.shuttleBoxChecked = false
          }
        })

        this.handleReset()
      }
    },
  },
  created() {},
  mounted() {
    this.getSelectedList()
  },
  methods: {
    handleReset() {
      this.parameterprojectName.projectName = null
      this.parameter.pageNow = 1
      this.getProject()
    },
    getProjectpageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getProject(this.parameterprojectName.projectName)
    },
    dataUpdate(row) {
      this.$forceUpdate()
      // this.getProject(row);
    },
    getProject(projectName = '') {
      if (this.issearch) {
        this.tableLoading = true
        this.$api.project
          .listProject({
            pageSize: this.filter.pageSize,
            pageNow: this.filter.pageNow,
            projectName,
          })
          .then(res => {
            this.selectedList = res.data.records.deepClone()
            if (this.projectListResponseList && this.projectListResponseList.length > 0) {
              this.projectListResponseList.forEach(project => {
                this.selectedList.forEach(selected => {
                  if (project.id === selected.id) {
                    selected.Selected = 1
                  }
                })
              })
            }
            this.filter.total = res.data.total
            this.tableLoading = false
          })
          .catch(err => {
            console.log(err)
            this.tableLoading = false
          })
      }
    },
    rowClick(row, column, event) {
      if (this.parameter.type == 'radio') {
        this.$emit('choiceDataChange', row)
        this.dlgVisible = false
      } else {
        if (!row.isCheckBox) {
          row.shuttleBoxChecked = !row.shuttleBoxChecked
          this.checkboxChange(row)
        }
      }
    },
    remove(i) {
      var obj = this.choiceList[i].deepClone()
      this.choiceList.splice(i, 1)

      for (let i = 0; i < this.selectedList.length; i++) {
        if (this.selectedList[i].id == obj.id) {
          this.selectedList[i].shuttleBoxChecked = false
          this.$set(this.selectedList, i, this.selectedList[i])
          return ''
        }
      }
    },
    move(i, j) {
      var obj = this.choiceList[i].deepClone()

      this.choiceList.splice(i, 1)

      this.choiceList.splice(i - j, 0, obj)
    },
    pageChangeHandler(val) {
      this.filter.pageNow = val
      this.getSelectedList()
    },
    handleSizeChange(val) {
      this.filter.pageSize = val
      this.filter.pageNow = 1
      this.getSelectedList()
    },
    getSelectedList() {
      if (this.projectManagerOptins == 1) return
      if (this.issearch) {
        this.getProject(this.parameterprojectName.projectName)
      } else {
        if (this.parameter.request) {
        } else {
          this.$message.error('request参数不能为空！')
        }

        let params = this.filter.combination(this.parameter.params)

        this.selectedListLoad = true
        this.parameter
          .request(params)
          .then(res => {
            for (let i = 0; i < res.data.records.length; i++) {
              let obj = res.data.records[i]
              obj.joinDate = ''

              if (
                this.choiceList.some(function (s) {
                  return s.id == obj.id
                })
              ) {
                obj.shuttleBoxChecked = true
              } else {
                obj.shuttleBoxChecked = false
              }
            }
            this.selectedList = res.data.records.deepClone()

            this.selectedList.forEach(v => {
              if (v.shuttleBoxChecked) {
                v.isCheckBox = true
              }
            })
            this.filter.total = res.data.total
            this.selectedListLoad = false
          })
          .catch(err => {
            console.log(err)
            this.selectedListLoad = false
          })
      }
    },
    checkboxChange(row) {
      if (row.shuttleBoxChecked) {
        this.choiceList.push(row)
      } else {
        for (let i = 0; i < this.choiceList.length; i++) {
          if (this.choiceList[i].id == row.id) {
            this.choiceList.splice(i, 1)
            return ''
          }
        }
      }
    },
    save() {
      let arr = this.choiceList.deepClone()
      arr.forEach(v => {
        v.isCheckBox = true
      })
      this.$emit('choiceDataChange', arr)
      this.dlgVisible = false
    },

    onClosed() {
      this.dlgVisible = false
      this.$emit('close', false)
    },
  },
}
</script>
<style scoped lang="scss"></style>
